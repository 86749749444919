import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./PublicPages/homepage";

import Dashboard from "./AdminPages/dashboard/dashboard";
import SignUpData from "./AdminPages/pages/signUpData";
import DashboardPage from "./AdminPages/pages/Dashboard";
import ScheduleData from "./AdminPages/pages/scheduleForm";
import Blogs from "./AdminPages/blogs/blogs";
import CreateBlogs from "./AdminPages/blogs/create";
import Profiles from "./AdminPages/profile/profile";
import CreateProfile from "./AdminPages/profile/create";
import SharedLink from "./AdminPages/profile/sharedLink";
import SharedProfiles from "./AdminPages/profile/sharedProfiles";
import EditProfile from "./AdminPages/profile/edit";
import EditBlog from "./AdminPages/blogs/edit";
import Login from "./AdminPages/form/login";

import api from "./API/api";
import PartnerCreatePage from "./AdminPages/partners/partners.create.page";

import CoordinatorCreatePage from "./AdminPages/coordinator/coordinators.create.page";

import PartnersManagePage from "./AdminPages/partners/partners.manage.page";
import PartnerManagePage from "./AdminPages/partners/partner.manage.page";
import PartnerManageLeadsPage from "./AdminPages/partners/partner.manage.leads.page";
import CoordinatorManageLeadsPage from "./AdminPages/coordinator/coordinators.leads.manage";
import LeadsManagePage from "./AdminPages/leads/leads.manage.page";
import LeadManagePage from "./AdminPages/leads/lead.manage.page";
import CoordinatorsCommissionsPage from "./AdminPages/coordinator/coordinators.commission.page";
import PartnersCommissionsPage from "./AdminPages/partners/partners.commission.page";
import CoordinatorsManagePage from "./AdminPages/coordinator/coordinators.manage.page";
import CoordinatorManagePage from "./AdminPages/coordinator/coordinator.manage.page";

import CoordinatorPartnersManagePage from "./AdminPages/coordinator/coordinators.partners.manage";

function App() {
  const [user, setUser] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    } else {
      setUser("public");
    }
  }, [sessionStorage.getItem("user")]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/department/:dept*" element={<Doctors />} /> */}
        {/* <Route path="/department/:dept*" element={<DoctorPage />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="SharedLink/:id" element={<SharedLink />} />
        {/* <Route path='/service/:slug' element={<ServicePage />} /> */}
      </Routes>

      {/* <Routes>
        <Route path='/admin' element={<Dashboard />} />
      </Routes> */}

      {user && user.role === "admin" && (
        <Routes>
          {/* admin  */}
          <Route path="/admin" element={<Dashboard />}>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="SignUpData" element={<SignUpData />} />
            <Route path="ScheduleData" element={<ScheduleData />} />
            <Route path="Blogs" element={<Blogs />} />
            <Route path="EditBlog" element={<EditBlog />} />
            <Route path="CreateBlogs" element={<CreateBlogs />} />
            <Route path="Profiles" element={<Profiles />} />
            <Route path="CreateProfile" element={<CreateProfile />} />
            <Route path="SharedLink" element={<SharedLink />} />
            <Route path="SharedProfiles" element={<SharedProfiles />} />
            <Route path="EditProfile" element={<EditProfile />} />

            <Route path="partners" element={<div>Partner Partner</div>} />
            <Route path="partners/create" element={<PartnerCreatePage />} />

            <Route path="partners/manage" element={<PartnersManagePage />} />
            <Route
              path="partners/manage/:partnerId"
              element={<PartnerManagePage />}
            />
            <Route
              path="partners/manage/:partnerId/leads"
              element={<PartnerManageLeadsPage />}
            />

            <Route
              path="partners/commissions"
              element={<PartnersCommissionsPage />}
            />

            <Route
              path="coordinators/create"
              element={<CoordinatorCreatePage />}
            />

            <Route
              path="coordinators/manage"
              element={<CoordinatorsManagePage />}
            />

            <Route
              path="coordinators/manage/:coordinatorId"
              element={<CoordinatorManagePage />}
            />

            <Route
              path="coordinators/manage/:coordinatorId/leads"
              element={<CoordinatorManageLeadsPage />}
            />

            <Route
              path="coordinators/manage/:coordinatorId/partners"
              element={<CoordinatorPartnersManagePage />}
            />

            <Route
              path="coordinators/commissions"
              element={<CoordinatorsCommissionsPage />}
            />

            {/* Leads related */}
            <Route path="leads/manage" element={<LeadsManagePage />} />

            <Route path="leads/manage/:leadId" element={<LeadManagePage />} />

            {/* <Route path='addDepartment' element={<AddDepartmentPage />} />
            <Route path='viewDepartment' element={<ViewDepartmentPage />} />
            <Route path='addAdminDoctor' element={<AddDoctorPage />} />
            <Route path='viewDoctor' element={<ViewDoctor />} />
            <Route path='AddServices' element={<AddServices />} />
            <Route path='CallLeads' element={<CallLeads />} /> */}
          </Route>
        </Routes>
      )}

      {user && user.role === "coordinator" && (
        <Routes>
          <Route path="/coordinator" element={<Dashboard />}>
            <Route path="dashboard" element={<DashboardPage />} />

            <Route path="partners/create" element={<PartnerCreatePage />} />

            <Route path="partners/manage" element={<PartnersManagePage />} />
            <Route
              path="partners/manage/:partnerId"
              element={<PartnerManagePage />}
            />
            <Route
              path="partners/manage/:partnerId/leads"
              element={<PartnerManageLeadsPage />}
            />

            <Route
              path="partners/commissions"
              element={<PartnersCommissionsPage />}
            />

            {/* Leads related */}
            <Route path="leads/manage" element={<LeadsManagePage />} />

            <Route path="leads/manage/:leadId" element={<LeadManagePage />} />
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
